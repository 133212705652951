import { useTranslation } from "react-i18next";
import { Alert, DescriptionText, Heading, Subheading, Wysiwyg } from "shared/components";
import { Field, Form } from "react-final-form";
import { StepWrapper, ErrorText } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useSignatureStep } from "../../../hooks";
import { EditorWrapper } from "./Signature.styles";
import { emailSignatureCodeViewValidator, emailSignatureValidator } from "./Signature.validation";
import { StepsContent } from "../../StepsContent";

export const Signature = () => {
  const { t, i18n } = useTranslation();

  const { data, loading, hasPrev, handlePrevStep, executeSubmitSignature } = useSignatureStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitSignature}
      render={({ handleSubmit, submitting, errors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.design.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.design.signature.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.design.signature.description")}
            </DescriptionText>
            <Field
              name="codeView"
              validate={emailSignatureCodeViewValidator}
              render={({ input: { onChange: onCodeViewChange } }) => (
                <Field
                  name="emailSignature"
                  validate={emailSignatureValidator}
                  render={({ input: { value, onChange } }) => (
                    <EditorWrapper>
                      {!loading && (
                        <Wysiwyg
                          index="signature"
                          language={i18n.language}
                          value={value}
                          onChange={onChange}
                          onCodeViewChange={(newState: boolean) => {
                            onCodeViewChange(newState);
                          }}
                        />
                      )}
                    </EditorWrapper>
                  )}
                />
              )}
            />
            <Alert severity="info">{t("questionnaireModule.steps.design.signature.hint")}</Alert>
            {errors && <ErrorText>{t(errors["codeView"] || errors["emailSignature"])}</ErrorText>}
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
