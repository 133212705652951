import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { FeatureVisibilityWrapper, Switch } from "shared/components";
import { useAiContentDescription } from "shared/hooks";
import { FormApi } from "final-form";
import { AiContentDescriptionModal } from "../AiContentDescriptionModal";
import {
  HeadingFormWaitingTimeAISwitchWrapper,
  HeadingFormWaitingTimeAISwitchLabel
} from "../TriggerSettingsFormStyles";
import { AiContentBlockProps } from "./AiContentBlock.props";
import { Wrapper, Title, Description } from "./AiContentBlock.styles";
import { AiTemplateContentDescriptionValues } from "shared/types";

export const AiContentBlock = ({
  switchState,
  translationKey,
  onEnable,
  onDisable
}: AiContentBlockProps) => {
  const { t } = useTranslation();

  const [showAiDescriptionModal, setShowAiDescriptionModal] = useState<boolean>(false);

  const { data: aiContentDescription, executeUpdateAIContentDescription } =
    useAiContentDescription();

  const handleAiContentSwitchClick = useCallback(() => {
    if (switchState) {
      onDisable();
    } else {
      if (!aiContentDescription?.description) {
        setShowAiDescriptionModal(true);
        return;
      }
      onEnable();
    }
  }, [switchState, aiContentDescription, onEnable, onDisable]);

  const handleCloseAiDescriptionModal = useCallback(() => {
    setShowAiDescriptionModal(false);
  }, []);

  const handleInitializeAiTemplateContent = useCallback(
    async (
      values: AiTemplateContentDescriptionValues,
      form: FormApi<AiTemplateContentDescriptionValues, AiTemplateContentDescriptionValues>
    ) => {
      const result = await executeUpdateAIContentDescription(values, form);
      if (result) {
        return result;
      }
      setShowAiDescriptionModal(false);
      await onEnable();
    },
    [executeUpdateAIContentDescription, onEnable]
  );

  return (
    <FeatureVisibilityWrapper requiredFeature="ai_based_template_content_enabled">
      <Wrapper>
        <Title>{t(`featuresModule.${translationKey}.aiContentBlock.title`)}</Title>
        <Description>
          {t(`featuresModule.${translationKey}.aiContentBlock.description`)}
        </Description>
        <HeadingFormWaitingTimeAISwitchWrapper>
          <Switch checked={switchState || false} onClick={handleAiContentSwitchClick} />
          <HeadingFormWaitingTimeAISwitchLabel>
            {t(`featuresModule.${translationKey}.aiContentBlock.switchLabel`)}
          </HeadingFormWaitingTimeAISwitchLabel>
        </HeadingFormWaitingTimeAISwitchWrapper>
        <AiContentDescriptionModal
          open={!!showAiDescriptionModal}
          onClose={handleCloseAiDescriptionModal}
          translationKey={translationKey}
          onSubmit={handleInitializeAiTemplateContent}
        />
      </Wrapper>
    </FeatureVisibilityWrapper>
  );
};
