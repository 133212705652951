import { Trans, useTranslation } from "react-i18next";
import { Alert, DescriptionText, Heading, Subheading, TextInput } from "shared/components";
import { Field, Form } from "react-final-form";
import { StepWrapper } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useLegalTextStep } from "../../../hooks";
import { legalTextValidator } from "./LegalText.validation";
import { StepsContent } from "../../StepsContent";

export const LegalText = () => {
  const { t } = useTranslation();

  const { data, hasPrev, handlePrevStep, executeSubmitLegalText } = useLegalTextStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitLegalText}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.design.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.design.legalText.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.design.legalText.description")}
            </DescriptionText>
            <Field
              name="legalText"
              validate={legalTextValidator}
              render={({
                input: { value, onChange, onBlur, onFocus },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <TextInput
                  required
                  label={t("questionnaireModule.steps.design.legalText.form.legalText.label")}
                  placeholder={t(
                    "questionnaireModule.steps.design.legalText.form.legalText.placeholder"
                  )}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  error={
                    (error || (submitErrors?.["legalText"] && !modifiedSinceLastSubmit)) && touched
                  }
                  errorMessage={t(submitErrors?.["legalText"] ?? error)}
                  multiline
                  marginTop={80}
                  marginBottom={40}
                />
              )}
            />
            <Alert>
              <Trans i18nKey={"questionnaireModule.steps.design.legalText.hint"} t={t} />
            </Alert>
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
