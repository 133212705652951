import { useTranslation } from "react-i18next";
import { Alert, Checkbox, DescriptionText, Heading, Subheading } from "shared/components";
import { Form, Field } from "react-final-form";
import { InputColumn, InputColumnName, InputsWrapper } from "./FormOfAddress.styles";
import { ErrorText, StepWrapper } from "../../StepStyles";
import { formOfAddressValidation } from "./FormOfAddress.validation";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useFormOfAddressStep } from "../../../hooks";
import { StepsContent } from "../../StepsContent";

export const FormOfAddress = () => {
  const { t } = useTranslation();

  const { data, hasPrev, contactEnabled, handlePrevStep, executeSubmitFormOfAddress } =
    useFormOfAddressStep();

  return (
    <Form
      initialValues={data}
      validate={formOfAddressValidation(contactEnabled)}
      onSubmit={executeSubmitFormOfAddress}
      render={({ handleSubmit, submitting, errors }) => {
        const globalError = errors?.pronominal || errors?.contact || errors?.communication;

        return (
          <StepWrapper>
            <StepsContent>
              <Heading>{t("questionnaireModule.steps.start.title")}</Heading>
              <Subheading>{t("questionnaireModule.steps.start.formOfAddress.title")}</Subheading>
              <DescriptionText>
                {t("questionnaireModule.steps.start.formOfAddress.description")}
              </DescriptionText>
              <InputsWrapper>
                <InputColumn>
                  <InputColumnName>
                    {t("questionnaireModule.steps.start.formOfAddress.form.pronominal")}
                  </InputColumnName>
                  <Field
                    name="pronominalUseFormal"
                    type="checkbox"
                    render={({ input }) => (
                      <Checkbox
                        label={t(
                          "questionnaireModule.steps.start.formOfAddress.form.pronominalUseFormal.label"
                        )}
                        {...input}
                        error={!!errors?.pronominal}
                      />
                    )}
                  />
                  <Field
                    name="pronominalUseInformal"
                    type="checkbox"
                    render={({ input }) => (
                      <Checkbox
                        label={t(
                          "questionnaireModule.steps.start.formOfAddress.form.pronominalUseInformal.label"
                        )}
                        {...input}
                        error={!!errors?.pronominal}
                      />
                    )}
                  />
                </InputColumn>
                {contactEnabled && (
                  <InputColumn>
                    <InputColumnName>
                      {t("questionnaireModule.steps.start.formOfAddress.form.contact")}
                    </InputColumnName>
                    <Field
                      name="contactUseFirstPerson"
                      type="checkbox"
                      render={({ input }) => (
                        <Checkbox
                          label={t(
                            "questionnaireModule.steps.start.formOfAddress.form.contactUseFirstPerson.label"
                          )}
                          {...input}
                          error={!!errors?.contact}
                        />
                      )}
                    />
                    <Field
                      name="contactUseTeam"
                      type="checkbox"
                      render={({ input }) => (
                        <Checkbox
                          label={t(
                            "questionnaireModule.steps.start.formOfAddress.form.contactUseTeam.label"
                          )}
                          {...input}
                          error={!!errors?.contact}
                        />
                      )}
                    />
                  </InputColumn>
                )}
                <InputColumn>
                  <InputColumnName>
                    {t("questionnaireModule.steps.start.formOfAddress.form.communication")}
                  </InputColumnName>
                  <Field
                    name="communicationUseWarmStyle"
                    type="checkbox"
                    render={({ input }) => (
                      <Checkbox
                        label={t(
                          "questionnaireModule.steps.start.formOfAddress.form.communicationUseWarmStyle.label"
                        )}
                        {...input}
                        error={!!errors?.communication}
                      />
                    )}
                  />
                  <Field
                    name="communicationUseCoolStyle"
                    type="checkbox"
                    render={({ input }) => (
                      <Checkbox
                        label={t(
                          "questionnaireModule.steps.start.formOfAddress.form.communicationUseCoolStyle.label"
                        )}
                        {...input}
                        error={!!errors?.communication}
                      />
                    )}
                  />
                  <Field
                    name="communicationUseFactually"
                    type="checkbox"
                    render={({ input }) => (
                      <Checkbox
                        label={t(
                          "questionnaireModule.steps.start.formOfAddress.form.communicationUseFactually.label"
                        )}
                        {...input}
                        error={!!errors?.communication}
                      />
                    )}
                  />
                </InputColumn>
              </InputsWrapper>
              <Alert severity="info">
                {t("questionnaireModule.steps.start.formOfAddress.form.infoText")}
              </Alert>
              {!!globalError && <ErrorText>{t(globalError)}</ErrorText>}
              <NavigationButtons
                prevDisabled={!hasPrev}
                nextLoading={submitting}
                onNextClick={handleSubmit}
                onPrevClick={handlePrevStep}
              />
            </StepsContent>
            <MobileStepper
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepWrapper>
        );
      }}
    />
  );
};
