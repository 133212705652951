import { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Subheading, DescriptionText, Switch, Divider, Slider } from "shared/components";
import { parseFormValueToSensitivity } from "shared/utils";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { SENSITIVITY_TYPES } from "config/constants";
import { addOptionsValidation } from "./AddOptions.validation";
import { getSliderMarks } from "./AddOptions.helpers";
import { ErrorText, StepWrapper } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { NavigationButtons } from "../../NavigationButtons";
import { useAddOptionsStep } from "../../../hooks";
import {
  FieldLine,
  FieldTitle,
  FieldsWrapper,
  SliderHintText,
  SliderWrapper
} from "./AddOptions.styles";
import { questionnaireAddOptionsFieldsConfig } from "../../../config";
import { StepsContent } from "../../StepsContent";

export const AddOptions = () => {
  const { t } = useTranslation();

  const { data, hasPrev, metadata, handlePrevStep, executeSubmitFormOfAddress } =
    useAddOptionsStep();

  const fields = useMemo(
    () => questionnaireAddOptionsFieldsConfig.filter((field) => metadata[field]),
    [metadata]
  );

  const getCurrentValueLabel = useCallback((value: number) => {
    const type = parseFormValueToSensitivity(value);
    return t(`questionnaireModule.steps.start.sensitivity.form.${type}.label`);
  }, []);

  const getCurrentValueHint = useCallback((value: number) => {
    const type = parseFormValueToSensitivity(value);
    return t(`questionnaireModule.steps.start.sensitivity.form.${type}.hint`);
  }, []);

  return (
    <Form
      initialValues={data}
      validate={addOptionsValidation(fields)}
      onSubmit={executeSubmitFormOfAddress}
      render={({ handleSubmit, submitting, errors, touched }) => {
        const hasError = errors?.[FORM_ERROR] && Object.values(touched ?? {}).some(Boolean);

        return (
          <StepWrapper>
            <StepsContent>
              <Heading>{t("questionnaireModule.steps.start.title")}</Heading>
              <Subheading>{t("questionnaireModule.steps.start.addOptions.title")}</Subheading>
              <DescriptionText>
                {t("questionnaireModule.steps.start.addOptions.description")}
              </DescriptionText>
              <FieldsWrapper>
                {fields.map((field, index) => (
                  <Fragment key={field}>
                    <Field
                      name={field}
                      type="checkbox"
                      render={({ input }) => (
                        <FieldLine>
                          <FieldTitle>
                            {t(`questionnaireModule.steps.start.addOptions.form.${field}.label`)}
                          </FieldTitle>
                          <Switch {...input} color={hasError ? "error" : "default"} />
                        </FieldLine>
                      )}
                    />
                    {index < fields.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </FieldsWrapper>
              <Subheading>{t("questionnaireModule.steps.start.sensitivity.title")}</Subheading>
              <DescriptionText>
                {t("questionnaireModule.steps.start.sensitivity.description")}
              </DescriptionText>
              <Field
                name="sensitivity"
                render={({ input: { value, onChange } }) => (
                  <>
                    <SliderWrapper>
                      <Slider
                        value={value}
                        onChange={onChange}
                        getAriaValueText={getCurrentValueLabel}
                        valueLabelFormat={getCurrentValueLabel}
                        valueLabelDisplay="auto"
                        marks={getSliderMarks()}
                        min={0}
                        max={100}
                        step={100 / (SENSITIVITY_TYPES.length - 1)}
                      />
                    </SliderWrapper>
                    <SliderHintText error={hasError}>{getCurrentValueHint(value)}</SliderHintText>
                  </>
                )}
              />
              {hasError && <ErrorText>{t(errors[FORM_ERROR])}</ErrorText>}
              <NavigationButtons
                prevDisabled={!hasPrev}
                nextLoading={submitting}
                onNextClick={handleSubmit}
                onPrevClick={handlePrevStep}
              />
            </StepsContent>
            <MobileStepper
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepWrapper>
        );
      }}
    />
  );
};
