import { useTranslation, Trans } from "react-i18next";
import { Heading, Subheading, DescriptionText, Tile, Link } from "shared/components";
import { Form, Field } from "react-final-form";
import { enabledProducts } from "./Products.validation";
import { OptionsWrapper } from "./Products.styles";
import { StepWrapper, ErrorText } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { NavigationButtons } from "../../NavigationButtons";
import { useProductsStep, useQuestionnaireProductsLinks } from "../../../hooks";
import {
  questionnaireProductsConfig,
  questionnaireProductsIconsConfig,
  questionnaireProductsLinksConfig,
  questionnaireProductsIconsSizesConfig
} from "../../../config";
import { StepsContent } from "../../StepsContent";

export const Products = () => {
  const { t } = useTranslation();

  const { data, hasPrev, handlePrevStep, executeSubmitProducts } = useProductsStep();

  const links = useQuestionnaireProductsLinks();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitProducts}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.start.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.start.products.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.start.products.description")}
            </DescriptionText>
            <Field
              name="enabledProducts"
              validate={enabledProducts}
              render={({
                input: { onChange, value },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <>
                  <OptionsWrapper
                    error={
                      (error || (submitErrors?.["enabledProducts"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                  >
                    {questionnaireProductsConfig.map((name) => (
                      <Tile
                        key={name}
                        active={value.includes(name)}
                        icon={questionnaireProductsIconsConfig[name]}
                        value={name}
                        label={t(`questionnaireModule.steps.start.products.${name}.label`)}
                        onClick={(newValue) => {
                          const newValues = value.includes(newValue)
                            ? value.filter((v: string) => v !== newValue)
                            : [...value, newValue];
                          onChange(newValues);
                        }}
                        iconParams={questionnaireProductsIconsSizesConfig[name]}
                        width={260}
                        height={176}
                        preserveCheck
                        infoText={
                          <Trans
                            t={t}
                            i18nKey={`questionnaireModule.steps.start.products.${name}.hint`}
                            components={[
                              <Link
                                key={name}
                                href={links[questionnaireProductsLinksConfig[name]]}
                                target="_blank"
                              />
                            ]}
                          />
                        }
                      />
                    ))}
                  </OptionsWrapper>
                  {(error || (submitErrors?.["enabledProducts"] && !modifiedSinceLastSubmit)) &&
                    touched && (
                      <ErrorText>{t(error || submitErrors?.["enabledProducts"])}</ErrorText>
                    )}
                </>
              )}
            />

            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
