import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";
import { SliderHintTextProps } from "./AddOptions.props";

export const FieldsWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin: 32px 0 84px;

      ${[breakpoints.up("md")]} {
        margin: 80px 0 120px;
      }
    }
  `
);

export const FieldLine = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `
);

export const FieldTitle = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => `
    &.MuiTypography-root {
      margin: 0 32px 0 12px;
      font-weight: ${body1.mobile.fontWeight};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      color: ${custom.white.main};
    }
  `
);

export const SliderWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: auto;
      margin: 47px 35px 0;

      ${breakpoints.up("md")} {
        width: 455px;
        margin: 64px auto 0;
      }
    }
  `
);

export const SliderHintText = styled((props: SliderHintTextProps) => <MuiTypography {...props} />, {
  shouldForwardProp: (prop) => prop !== "error"
})(
  ({
    theme: {
      palette: { custom },
      typography: { body2 },
      breakpoints
    },
    error
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: 14px;
      line-height: 21px;
      font-weight: ${body2.desktop.fontWeight};
      text-align: center;
      margin: 15px 0 ${error ? "0px" : "56px"};

      ${breakpoints.up("md")} {
        margin: 15px 70px ${error ? "0px" : "56px"};
      }
    }
  `
);
