import { ValidationErrors, FORM_ERROR } from "final-form";
import {
  QuestionnaireAddOptionsFormFields,
  QuestionnaireAddOptionsFormValues
} from "../../../types";

export const addOptionsValidation =
  (visibleFields: (keyof QuestionnaireAddOptionsFormFields)[]) =>
  (values: QuestionnaireAddOptionsFormValues): ValidationErrors => {
    let result: ValidationErrors = {};

    const filteredFields = Object.entries(values).filter(([key]) =>
      visibleFields.includes(key as keyof QuestionnaireAddOptionsFormFields)
    );

    if (filteredFields.every(([, field]) => field === false)) {
      result = {
        ...result,
        [FORM_ERROR]: "questionnaireModule.errors.atLeastOneActivated"
      };
    }

    return result;
  };
