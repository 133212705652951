import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";
import { InputWrapperProps } from "./StepStyles.props";

export const StepWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: auto;
      margin-left: 0;

      ${[breakpoints.up("lg")]} {
        margin-left: 415px;
      }
    }
  `
);

export const IntroductionCheckboxWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-top: 24px;
    }
  `
);

export const LoadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0;
    }
  `
);

export const ErrorText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.coral.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      margin: 30px 0 8px;
      text-align: center;

      ${[breakpoints.up("md")]} {
        font-size: ${body1.tablet.fontSize};
        line-height: ${body1.tablet.lineHeight};
        font-weight: ${body1.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
      }
    }
  `
);

export const InputsWrappers = styled((props: InputWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "singleChild"
})(
  ({ theme: { breakpoints }, singleChild }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      justify-content: initial;
      margin-top: 0px;

      &:first-of-type {
        margin-top: 44px;
      }
      
      ${[breakpoints.up("md")]} {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8px;

        &:first-of-type {
          margin-top: 64px;
        }
      }

      & > .MuiBox-root {
        flex: 1;

        &:first-of-type {
          margin-right: 0;

          ${[breakpoints.up("md")]} {
            margin-right: ${singleChild ? "0px" : "24px"};
          }
        }
      }
    }
  `
);

export const VoucherFieldWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    width: 100%;
    margin: 0 auto;

    ${[breakpoints.up("md")]} {
      width: 506px;
    }
`
);

export const ValueAlertWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
    }
  `
);

export const DividerWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      & > .MuiDivider-root {
        margin-top: 32px;
      }
    }
  `
);
