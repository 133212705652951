import { styled, Box as MuiBox } from "@mui/material";
import { StepsContentStylesProps } from "./StepsContent.props";

export const StepsContentStyles = styled(
  (props: StepsContentStylesProps) => <MuiBox {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "topOffset"
  }
)(
  ({ theme: { breakpoints }, topOffset }) => `
  &.MuiBox-root {
    margin: 0 20px;
    padding: ${topOffset + 80}px 0 192px;

    ${[breakpoints.up("sm")]} {
      padding: ${topOffset + 104}px 0 192px;
      margin: 0 auto;
      width: 500px;
    }

    ${[breakpoints.up("md")]} {
      width: 760px;
    }

    ${[breakpoints.up("lg")]} {
      padding: ${topOffset + 124}px 0 65px;
    }

    ${[breakpoints.up("xl")]} { 
      width: 950px;
    }
  }
  `
);
