import { Form, Field } from "react-final-form";
import {
  Heading,
  Subheading,
  DescriptionText,
  Switch,
  ContainedButton,
  TextInput,
  LoadingIndicator,
  FeatureVisibilityWrapper
} from "shared/components";
import { Permission } from "shared/types";
import { useAiContentDescription, useAuthState } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  HeadingFormSubheadingWrapper,
  HeadingFormWaitingTimeWrapper,
  HeadingFormButtonWrapper,
  HeadingFormInputWrapper,
  HeadingFormLoadingWrapper,
  HeadingFormWaitingTimeAISwitchWrapper,
  HeadingFormWaitingTimeAISwitchLabel,
  AdministrationBlockWrapper,
  AdministrationButtonWrapper,
  AdministrationStatusWrapper,
  HeadingFormWrapper,
  SwitchWrapper,
  SwitchLabel
} from "../TriggerSettingsFormStyles";
import {
  useComeBackMailingConfigData,
  useUpdateComeBackMailingConfig,
  useEnableComeBackMailingConfig,
  useDisableComeBackMailingConfig,
  useReprocessComeBackMailingExistingSessions,
  useComeBackMailingReprocessStatus,
  useComeBackMailingCanEnable,
  useEnableComeBackMailingAiMinimumWaitingHours,
  useDisableComeBackMailingAiMinimumWaitingHours,
  useEnableComeBackMailingAiTemplateContent,
  useDisableComeBackMailingAiTemplateContent
} from "../../hooks";
import { minimumWaitingHoursValidator } from "./ComeBackMailingContentHeadingForm.validation";
import { ReprocessStatusIcon } from "../ReprocessStatusIcon";
import { AiContentBlock } from "../AiContentBlock";

export const ComeBackMailingContentHeadingForm = () => {
  const { getRole } = useAuthState();

  const { t } = useTranslation();

  const { canEnable, loading: loadingCanEnable } = useComeBackMailingCanEnable();

  const { initialState, loading: loadingConfigData } = useComeBackMailingConfigData();

  const { loading: aiContentDataLoading } = useAiContentDescription();

  const loading = loadingCanEnable || loadingConfigData || aiContentDataLoading;

  const { initialState: reprocessStatusInitialState, loading: reprocessStatusLoading } =
    useComeBackMailingReprocessStatus();

  const update = useUpdateComeBackMailingConfig();

  const enableTrigger = useEnableComeBackMailingConfig();

  const disableTrigger = useDisableComeBackMailingConfig();

  const enableAiMinimumWaitingHours = useEnableComeBackMailingAiMinimumWaitingHours();

  const disableAiMinimumWaitingHours = useDisableComeBackMailingAiMinimumWaitingHours();

  const enableAiTemplateContent = useEnableComeBackMailingAiTemplateContent();

  const disableAiTemplateContent = useDisableComeBackMailingAiTemplateContent();

  const { executeEnableComeBackMailingConfig: reprocess, loading: reprocessLoading } =
    useReprocessComeBackMailingExistingSessions();

  const handleSwitchClick = useCallback(() => {
    if (initialState?.enabled) {
      disableTrigger();
    } else {
      enableTrigger();
    }
  }, [initialState?.enabled, enableTrigger, disableTrigger]);

  const handleAISwitchClick = useCallback(() => {
    if (initialState?.aiBasedMinimumWaitingHours) {
      disableAiMinimumWaitingHours();
    } else {
      enableAiMinimumWaitingHours();
    }
  }, [
    initialState?.aiBasedMinimumWaitingHours,
    enableAiMinimumWaitingHours,
    disableAiMinimumWaitingHours
  ]);

  const showAdministrationBlock = getRole() === Permission.ADMIN || getRole() === Permission.SALES;

  const scheduled = reprocessStatusInitialState?.scheduled;

  const scheduledTooltip = scheduled
    ? t("featuresModule.comeBackMailing.administration.scheduledTooltip")
    : t("featuresModule.comeBackMailing.administration.notScheduledTooltip");

  const reprocessButtonTitle = scheduled
    ? t("featuresModule.comeBackMailing.administration.scheduledTooltip")
    : t("featuresModule.comeBackMailing.administration.reprocess");

  return (
    <Form
      initialValues={initialState}
      onSubmit={update}
      render={({ handleSubmit, submitting, dirty, submitErrors }) => (
        <>
          <Heading>{t("featuresModule.comeBackMailing.heading")}</Heading>
          <HeadingFormSubheadingWrapper>
            <Subheading>{t("featuresModule.comeBackMailing.subheading")}</Subheading>
            <SwitchWrapper>
              <Switch
                disabled={!canEnable}
                checked={initialState?.enabled || false}
                color={initialState?.enabled ? "default" : "error"}
                onClick={handleSwitchClick}
              />
              <SwitchLabel>
                {t(
                  `featuresModule.comeBackMailing.featureSwitch.${
                    initialState?.enabled ? "active" : "inactive"
                  }`
                )}
              </SwitchLabel>
            </SwitchWrapper>
          </HeadingFormSubheadingWrapper>
          <DescriptionText>{t("featuresModule.comeBackMailing.description")}</DescriptionText>
          {loading ? (
            <HeadingFormLoadingWrapper>
              <LoadingIndicator />
            </HeadingFormLoadingWrapper>
          ) : (
            <HeadingFormWrapper>
              <HeadingFormWaitingTimeWrapper>
                <Field
                  name="minimumWaitingHours"
                  validate={minimumWaitingHoursValidator}
                  render={({
                    input: { onChange, value, onBlur, onFocus },
                    meta: { error, touched, modifiedSinceLastSubmit }
                  }) => {
                    const inputValue = value ? value : "";

                    const resultValue = initialState?.aiBasedMinimumWaitingHours ? "0" : inputValue;

                    return (
                      <HeadingFormInputWrapper>
                        <TextInput
                          disabled={initialState?.aiBasedMinimumWaitingHours}
                          label={t("featuresModule.comeBackMailing.form.minimumWaitingHours.label")}
                          placeholder={t(
                            "featuresModule.comeBackMailing.form.minimumWaitingHours.placeholder"
                          )}
                          marginBottom={24}
                          value={resultValue}
                          onChange={(value) => {
                            onChange(Number(value.replace(/[^0-9]/g, "")));
                          }}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={
                            (error ||
                              (submitErrors?.["minimumWaitingHours"] &&
                                !modifiedSinceLastSubmit)) &&
                            touched
                          }
                          errorMessage={t(submitErrors?.["minimumWaitingHours"] ?? error)}
                        />
                      </HeadingFormInputWrapper>
                    );
                  }}
                />
                <HeadingFormButtonWrapper>
                  <ContainedButton
                    type="submit"
                    disabled={initialState?.aiBasedMinimumWaitingHours || !dirty}
                    loading={submitting}
                    onClick={handleSubmit}
                  >
                    {t("featuresModule.saveButton")}
                  </ContainedButton>
                </HeadingFormButtonWrapper>
              </HeadingFormWaitingTimeWrapper>
              <FeatureVisibilityWrapper requiredFeature="come_back_mailing_ai_waiting_time_enabled">
                <HeadingFormWaitingTimeAISwitchWrapper>
                  <Switch
                    checked={initialState?.aiBasedMinimumWaitingHours || false}
                    onClick={handleAISwitchClick}
                  />
                  <HeadingFormWaitingTimeAISwitchLabel>
                    {t("featuresModule.comeBackMailing.form.minimumWaitingHours.aiSwitch")}
                  </HeadingFormWaitingTimeAISwitchLabel>
                </HeadingFormWaitingTimeAISwitchWrapper>
              </FeatureVisibilityWrapper>
              <AiContentBlock
                switchState={initialState?.aiBasedTemplateContent}
                translationKey="comeBackMailing"
                onEnable={enableAiTemplateContent}
                onDisable={disableAiTemplateContent}
              />
            </HeadingFormWrapper>
          )}
          {showAdministrationBlock && (
            <AdministrationBlockWrapper>
              <AdministrationStatusWrapper>
                <Subheading>{t("featuresModule.comeBackMailing.administration.header")}</Subheading>
                {!reprocessStatusLoading && (
                  <ReprocessStatusIcon scheduled={scheduled} tooltip={scheduledTooltip} />
                )}
              </AdministrationStatusWrapper>
              <DescriptionText>
                {t("featuresModule.comeBackMailing.administration.reprocessDescription")}
              </DescriptionText>
              <AdministrationButtonWrapper>
                <ContainedButton
                  disabled={loading || scheduled}
                  loading={reprocessLoading || reprocessStatusLoading}
                  onClick={reprocess}
                >
                  {reprocessButtonTitle}
                </ContainedButton>
              </AdministrationButtonWrapper>
            </AdministrationBlockWrapper>
          )}
        </>
      )}
    />
  );
};
