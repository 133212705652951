import { FieldValidator } from "final-form";

export const aiContentShopDescriptionValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }

  if (value.length > 150) {
    return "featuresModule.errors.lessThan150Characters";
  }
};
