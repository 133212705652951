import { useTranslation } from "react-i18next";
import { DescriptionText, Heading, Subheading, TextInput } from "shared/components";
import { Form, Field } from "react-final-form";
import { StepWrapper, InputsWrappers } from "../../StepStyles";
import { shopNameValidator, webUrlValidator } from "./Account.validation";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useAccountStep } from "../../../hooks";
import { StepsContent } from "../../StepsContent";

export const Account = () => {
  const { t } = useTranslation();

  const { data, hasPrev, handlePrevStep, executeSubmitAccount } = useAccountStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitAccount}
      render={({ handleSubmit, submitErrors, submitting }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.start.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.start.account.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.start.account.description")}
            </DescriptionText>
            <InputsWrappers>
              <Field
                name="shopName"
                validate={shopNameValidator}
                render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                  <TextInput
                    required
                    {...input}
                    label={t("questionnaireModule.steps.start.account.form.shopName.label")}
                    placeholder={t(
                      "questionnaireModule.steps.start.account.form.shopName.placeholder"
                    )}
                    error={
                      (error || (submitErrors?.["shopName"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["shopName"] ?? error)}
                  />
                )}
              />
              <Field
                name="webUrl"
                validate={webUrlValidator}
                render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                  <TextInput
                    required
                    {...input}
                    label={t("questionnaireModule.steps.start.account.form.webUrl.label")}
                    placeholder={t(
                      "questionnaireModule.steps.start.account.form.webUrl.placeholder"
                    )}
                    error={
                      (error || (submitErrors?.["webUrl"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["webUrl"] ?? error)}
                  />
                )}
              />
            </InputsWrappers>
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
