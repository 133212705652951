import { ModalStyles, Container, ModalHeader, ModalTitle, IconButton } from "./Modal.styles";
import { ModalProps } from "./Modal.props";
import { PropsWithChildren } from "react";
import { Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Modal = ({ open, onClose, children, title }: PropsWithChildren<ModalProps>) => {
  return (
    <ModalStyles open={open} onClose={onClose}>
      <Fade in={open}>
        <Container>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {children}
        </Container>
      </Fade>
    </ModalStyles>
  );
};
