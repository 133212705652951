import { styled, Typography as MuiTypography, Box as MuiBox } from "@mui/material";

export const ContentWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 30px 24px 40px;

      ${breakpoints.up("md")} {
        width: 600px;
      }

      ${breakpoints.up("lg")} {
        width: 1000px;
      }

      ${breakpoints.up("xl")} {

      }
    }
  `
);

export const FieldsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
    }
  `
);

export const Description = styled(MuiTypography)(
  ({
    theme: {
      typography: { body1, h4 }
    }
  }) => `
    &.MuiTypography-root {
      font-size: ${body1.mobile.fontSize};
      line-height: ${h4.desktop.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      width: 100%;
      margin-bottom: 40px;
    }
  `
);
