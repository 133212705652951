import { PropsWithChildren } from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Modal, TextInput, ContainedButton } from "shared/components";
import { AiContentDescriptionModalProps } from "./AiContentDescriptionModal.props";
import { ContentWrapper, Description, FieldsWrapper } from "./AiContentDescriptionModal.styles";
import { aiContentShopDescriptionValidator } from "./AiContentDescriptionModal.validation";

export const AiContentDescriptionModal = ({
  translationKey,
  open,
  onClose,
  onSubmit
}: PropsWithChildren<AiContentDescriptionModalProps>) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t(`featuresModule.${translationKey}.modal.aiTemplateContent.title`)}
      onClose={onClose}
    >
      <ContentWrapper>
        <Description>
          {t(`featuresModule.${translationKey}.modal.aiTemplateContent.description`)}
        </Description>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty, submitting, submitErrors }) => (
            <FieldsWrapper>
              <Field
                name="description"
                validate={aiContentShopDescriptionValidator}
                render={({
                  input: { onChange, value, onBlur, onFocus },
                  meta: { error, modifiedSinceLastSubmit, touched }
                }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    multiline
                    label={t(
                      `featuresModule.${translationKey}.form.aiTemplateContent.description.label`
                    )}
                    placeholder={t(
                      `featuresModule.${translationKey}.form.aiTemplateContent.description.placeholder`
                    )}
                    error={
                      (error || (submitErrors?.["description"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["description"] ?? error)}
                  />
                )}
              />
              <ContainedButton
                type="submit"
                disabled={!dirty}
                loading={submitting}
                onClick={handleSubmit}
              >
                {t(`featuresModule.${translationKey}.modal.aiTemplateContent.button`)}
              </ContainedButton>
            </FieldsWrapper>
          )}
        />
      </ContentWrapper>
    </Modal>
  );
};
