import { QuestionnaireAddOptionsFormFields } from "../types/";

export const questionnaireAddOptionsFieldsConfig: (keyof QuestionnaireAddOptionsFormFields)[] = [
  "phoneSupportEnabled",
  "emailSupportEnabled",
  "activationPopupVoucherEnabled",
  "abandonmentMailVoucherEnabled",
  "newsletterVoucherEnabled",
  "browseAbandonmentVoucherEnabled",
  "comeBackMailingVoucherEnabled"
];
