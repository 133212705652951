import { useMemo } from "react";
import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared/hooks";
import { parseSensitivityToFormValue } from "shared/utils";
import { DEFAULT_SENSITIVITY_TYPE } from "config/constants";
import { getSensitivity } from "../../api";

export const useSensitivityData = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper(
    [queryName, selectedShopId],
    async () => await getSensitivity(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const initialState = useMemo(
    () => ({
      sensitivity: parseSensitivityToFormValue(data ? data.sensitivity : DEFAULT_SENSITIVITY_TYPE)
    }),
    [data]
  );

  return { initialState, loading: isLoading };
};
