import {
  styled,
  Modal as MuiModal,
  Box as MuiBox,
  Typography as MuiTypography,
  IconButton as MuiIconButton
} from "@mui/material";

export const ModalStyles = styled(MuiModal)(
  () => `
    &.MuiPaper-root {

    }
  `
);

export const Container = styled(MuiBox)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiBox-root {
      position: absolute;
      background-color: ${custom.background};
      border-radius: 5px;
      width: 100%;
      top: 50%;
      transform: translate(0, -50%);

      ${breakpoints.up("md")} {
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
      }
    }
  `
);

export const ModalHeader = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      padding: 20px 34px;
      align-items: center;
      background-color: ${custom.grey.light};
      border-radius: 5px 5px 0 0;
    }
  `
);

export const ModalTitle = styled(MuiTypography)(
  () => `
    &.MuiTypography-root {
    
    }
  `
);

export const IconButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      margin-left: auto;
      color: ${custom.white.main};
      width: 24px;
      height: 24px;
      padding: 0;
    }
  `
);
