import { useTranslation } from "react-i18next";
import { Heading, Subheading, DescriptionText } from "shared/components";
import { Field, Form } from "react-final-form";
import { StepWrapper } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { NavigationButtons } from "../../NavigationButtons";
import { useCustomerSupportAvailabilityStep } from "../../../hooks";
import { Reachability, findFirstError, hasError } from "../../Reachability";
import { InputWrapper } from "./CustomerSupportAvailability.styles";
import { availabilityValidator } from "./CustomerSupportAvailability.validation";
import { StepsContent } from "../../StepsContent";

export const CustomerSupportAvailability = () => {
  const { t } = useTranslation();

  const { data, hasPrev, handlePrevStep, executeSubmitCustomerSupportAvailability } =
    useCustomerSupportAvailabilityStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitCustomerSupportAvailability}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.customer.title")}</Heading>
            <Subheading>
              {t("questionnaireModule.steps.customer.customerSupportAvailability.title")}
            </Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.customer.customerSupportAvailability.description")}
            </DescriptionText>
            <Field
              name="availability"
              validate={availabilityValidator}
              render={({
                input: { value, onFocus, onChange, onBlur },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => {
                // Check that submission error exists and that the field has been touched
                const hasSubmissionError =
                  submitErrors?.availability && !modifiedSinceLastSubmit && touched;

                // Check if the field has been touched and has a global error
                const globalError =
                  error?.global && !modifiedSinceLastSubmit && touched
                    ? t(error?.global)
                    : undefined;

                // Check if the field has been touched and has a validation error
                const validationError = error ? t(findFirstError(error) ?? "") : undefined;

                // Translate the first submission error
                const submissionError = hasSubmissionError
                  ? t(findFirstError(submitErrors?.availability) ?? "")
                  : undefined;

                // Prepare the error object from the submission error
                const submissionErrorObject = hasSubmissionError
                  ? submitErrors?.availability
                  : undefined;

                // Prepare the error object from the validation error
                const validationErrorObject = error ?? undefined;

                // Take the first error object that exists
                const resultErrorObject = validationErrorObject ?? submissionErrorObject;

                // Take the first error text that exists
                const resultErrorText = globalError ?? validationError ?? submissionError;

                return (
                  <InputWrapper hasError={hasError(submitErrors ?? error)}>
                    <Reachability
                      value={value}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      errors={resultErrorObject}
                      errorText={resultErrorText}
                    />
                  </InputWrapper>
                );
              }}
            />

            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
