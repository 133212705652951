import { apiClient } from "../utils";
import { AiTemplateContentDescription, PutAiContentDescriptionRequest } from "api/models";

export const putAiContentDescription = async ({
  shopId,
  description
}: PutAiContentDescriptionRequest) => {
  const { data } = await apiClient.put<AiTemplateContentDescription>(
    `/${shopId}/ai-content-description`,
    {
      description
    }
  );

  return data;
};
