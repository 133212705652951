import { apiClient } from "../utils";
import { AiTemplateContentDescription } from "api/models";

export const getAiContentDescription = async (shopId: number) => {
  const { data } = await apiClient.get<AiTemplateContentDescription>(
    `/${shopId}/ai-content-description`
  );

  return data;
};
