import { QuestionnaireAddOptionsStepData, QuestionnaireAddOptionsStepMetadata } from "api/models";
import { DEFAULT_SENSITIVITY_TYPE } from "config/constants";
import { parseFormValueToSensitivity, parseSensitivityToFormValue } from "shared/utils";
import {
  QuestionnaireAddOptionsFormMetadataState,
  QuestionnaireAddOptionsFormValues
} from "../../types";

export const parseAddOptionsFormMetadata = (
  metadata: QuestionnaireAddOptionsStepMetadata | undefined
): QuestionnaireAddOptionsFormMetadataState => {
  const fields = metadata?.fields;

  const emailSupportEnabled = !fields?.emailSupportEnabled?.disabled;

  const phoneSupportEnabled = !fields?.phoneSupportEnabled?.disabled;

  const activationPopupVoucherEnabled = !fields?.activationPopupVoucherEnabled?.disabled;

  const abandonmentMailVoucherEnabled = !fields?.abandonmentMailVoucherEnabled?.disabled;

  const newsletterVoucherEnabled = !fields?.newsletterVoucherEnabled?.disabled;

  const browseAbandonmentVoucherEnabled = !fields?.browseAbandonmentVoucherEnabled?.disabled;

  const comeBackMailingVoucherEnabled = !fields?.comeBackMailingVoucherEnabled?.disabled;

  return {
    emailSupportEnabled,
    phoneSupportEnabled,
    activationPopupVoucherEnabled,
    abandonmentMailVoucherEnabled,
    newsletterVoucherEnabled,
    browseAbandonmentVoucherEnabled,
    comeBackMailingVoucherEnabled
  };
};

export const parseAddOptionsFormValues = (
  data: QuestionnaireAddOptionsStepData | undefined
): QuestionnaireAddOptionsFormValues => {
  return {
    abandonmentMailVoucherEnabled: !!data?.abandonmentMailVoucherEnabled,
    activationPopupVoucherEnabled: !!data?.activationPopupVoucherEnabled,
    emailSupportEnabled: !!data?.emailSupportEnabled,
    newsletterVoucherEnabled: !!data?.newsletterVoucherEnabled,
    phoneSupportEnabled: !!data?.phoneSupportEnabled,
    browseAbandonmentVoucherEnabled: !!data?.browseAbandonmentVoucherEnabled,
    comeBackMailingVoucherEnabled: !!data?.comeBackMailingVoucherEnabled,
    sensitivity: parseSensitivityToFormValue(
      data?.sensitivity ? data.sensitivity : DEFAULT_SENSITIVITY_TYPE
    )
  };
};

export const addOptionsFormMapper = (
  {
    abandonmentMailVoucherEnabled,
    activationPopupVoucherEnabled,
    emailSupportEnabled,
    newsletterVoucherEnabled,
    phoneSupportEnabled,
    browseAbandonmentVoucherEnabled,
    comeBackMailingVoucherEnabled,
    sensitivity
  }: QuestionnaireAddOptionsFormValues,
  {
    abandonmentMailVoucherEnabled: abandonmentMailVoucherActive,
    activationPopupVoucherEnabled: activationPopupVoucherActive,
    emailSupportEnabled: emailSupportActive,
    newsletterVoucherEnabled: newsletterVoucherActive,
    phoneSupportEnabled: phoneSupportActive,
    browseAbandonmentVoucherEnabled: browseAbandonmentVoucherActive,
    comeBackMailingVoucherEnabled: comeBackMailingVoucherActive
  }: QuestionnaireAddOptionsFormMetadataState
): QuestionnaireAddOptionsStepData => {
  return {
    abandonmentMailVoucherEnabled: abandonmentMailVoucherActive
      ? !!abandonmentMailVoucherEnabled
      : undefined,
    activationPopupVoucherEnabled: activationPopupVoucherActive
      ? !!activationPopupVoucherEnabled
      : undefined,
    emailSupportEnabled: emailSupportActive ? !!emailSupportEnabled : undefined,
    newsletterVoucherEnabled: newsletterVoucherActive ? !!newsletterVoucherEnabled : undefined,
    phoneSupportEnabled: phoneSupportActive ? !!phoneSupportEnabled : undefined,
    browseAbandonmentVoucherEnabled: browseAbandonmentVoucherActive
      ? !!browseAbandonmentVoucherEnabled
      : undefined,
    comeBackMailingVoucherEnabled: comeBackMailingVoucherActive
      ? !!comeBackMailingVoucherEnabled
      : undefined,
    sensitivity: parseFormValueToSensitivity(sensitivity)
  };
};
