import { FormProps } from "react-final-form";
import { useCallback } from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiTemplateContentDescription, PutAiContentDescriptionRequest } from "api/models";
import { useSelectedShop } from "../useSelectedShop";
import { getAiContentDescription, putAiContentDescription } from "../../api";
import { useQueryWrapper } from "../useQueryWrapper";
import { useErrorFormatter } from "../../utils";
import { useNotifications } from "../useNotifications";
import { NetworkError } from "../../types";
import { AiTemplateContentDescriptionValues } from "shared/types";

export const useAiContentDescription = () => {
  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { data, isLoading } = useQueryWrapper(
    ["aiContentDescription", selectedShopId],
    async () => getAiContentDescription(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const { mutateAsync: updateAIContentDescription } = useMutation<
    AiTemplateContentDescription,
    AxiosError<{ message: string }>,
    PutAiContentDescriptionRequest
  >(putAiContentDescription);

  const executeUpdateAIContentDescription: FormProps<
    AiTemplateContentDescriptionValues,
    AiTemplateContentDescriptionValues
  >["onSubmit"] = useCallback(
    async ({ description }, form) => {
      try {
        await updateAIContentDescription({
          shopId: selectedShopId!,
          description
        });

        await queryClient.invalidateQueries(["aiContentDescription", selectedShopId]);

        form.reset();
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [selectedShopId, showNotification]
  );

  return { data, loading: isLoading, executeUpdateAIContentDescription };
};
