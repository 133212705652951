import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin: 104px 0 80px;
    }
  `
);

export const Title = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1, h4 }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${h4.desktop.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      margin-bottom: 10px;
    }
  `
);

export const Description = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1, h4 }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${h4.desktop.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      margin-bottom: 40px;
    }
  `
);
