import { useTranslation } from "react-i18next";
import { SENSITIVITY_TYPES } from "config/constants";
import { useMemo } from "react";

export const getSliderMarks = (): { value: number; label: string }[] => {
  const { t, i18n } = useTranslation();

  return useMemo(
    () =>
      SENSITIVITY_TYPES.map((type, index, arr) => {
        const fraction = 100 / (arr.length - 1);

        const value = index * fraction;

        const label = [0, 100].includes(value)
          ? t(`questionnaireModule.steps.start.sensitivity.form.${type}.label`)
          : "";

        return {
          value,
          label
        };
      }),
    [i18n.language]
  );
};
