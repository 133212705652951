import { styled, Box as MuiBox } from "@mui/material";
import { WrapperStylesProps } from "./Stepper.props";

export const Wrapper = styled((props: WrapperStylesProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "topOffset"
})(
  ({
    theme: {
      palette: { custom }
    },
    topOffset
  }) => `
    &.MuiBox-root {
      position: fixed;
      top: ${topOffset + 64}px;
      left: 0;
      bottom: 0;
      width: 415px;
      padding: 80px 0 80px 35px;
      background-color: ${custom.white.main}0D;
      backdrop-filter: blur(5px);
      overflow-x: auto;
    }
  `
);
