import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { postComeBackMailingAiTemplateContentDisable } from "../../api";

export const useDisableComeBackMailingAiTemplateContent = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: disableComeBackMailingAITemplateContent } = useMutation<
    void,
    AxiosError<{ message: string }>,
    number
  >(postComeBackMailingAiTemplateContentDisable);

  const executeDisableComeBackMailingAITemplateContent = useCallback(async () => {
    try {
      await disableComeBackMailingAITemplateContent(selectedShopId!);

      queryClient.invalidateQueries([queryName, selectedShopId]);

      showNotification("featuresModule.comeBackMailing.deactivatedMessage", "success");
    } catch (error) {
      return formatError(error as NetworkError);
    }
  }, [queryName, selectedShopId, showNotification]);

  return executeDisableComeBackMailingAITemplateContent;
};
