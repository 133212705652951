export enum AccountingTaxRateGlobal {
  zero = "noTax",
  vatReduced = "379911000004333190",
  standard = "379911000004515877"
}

export enum AccountingTaxRateGermany {
  zero = "noTax",
  standard = "379911000017062107"
}
