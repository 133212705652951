import { apiClient } from "shared/utils";
import { QuestionnaireAddOptionsStepData, ShopIdParam } from "api/models";

export const putSubmitAddOptions = async ({
  shopId,
  ...values
}: QuestionnaireAddOptionsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/add-options`, values);

  return data;
};
