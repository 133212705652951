import { useCallback, useMemo } from "react";
import { QuestionnaireAddOptionsStepData, QuestionnaireStepType } from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitAddOptions } from "../../api";
import { QuestionnaireAddOptionsFormValues } from "../../types";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import {
  addOptionsFormMapper,
  parseAddOptionsFormMetadata,
  parseAddOptionsFormValues
} from "./useAddOptionsStep.helpers";

export const useAddOptionsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ADD_OPTIONS);

  const data = useMemo(() => parseAddOptionsFormValues(stepData?.data), [stepData?.data]);

  const metadata = useMemo(
    () => parseAddOptionsFormMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitAddOptions =
    useQuestionnaireSubmitMutation<QuestionnaireAddOptionsStepData>(putSubmitAddOptions);

  const executeSubmitFormOfAddress: FormProps<
    QuestionnaireAddOptionsFormValues,
    QuestionnaireAddOptionsFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const addOptionSubmitData = addOptionsFormMapper(formValues, metadata);

        await submitAddOptions(addOptionSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAddOptions, metadata]
  );

  return {
    data,
    hasPrev: !!prevStep,
    metadata,
    handlePrevStep,
    executeSubmitFormOfAddress
  };
};
