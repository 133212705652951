import { Drawer as MuiDrawer, styled } from "@mui/material";
import { NavigationBarDrawerStylesProps } from "./NavigationBarDrawer.props";

export const NavigationBarDrawerStyles = styled(
  (props: NavigationBarDrawerStylesProps) => <MuiDrawer {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "topOffset"
  }
)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    },
    topOffset
  }) => `
  &.MuiDrawer-root {
    z-index: 1100;

    & .MuiDrawer-paper { 
      background-color: ${custom.background};
      width: 100vw;
      padding-top: ${topOffset + 64}px;
      overflow-y: auto;
      overflow-x: hidden;
      transition: padding-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
      ${breakpoints.up("sm")} {
        width: 400px;
      }
    }
  }
`
);
