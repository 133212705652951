import { Trans, useTranslation } from "react-i18next";
import {
  Alert,
  DescriptionText,
  Heading,
  SelectDropdown,
  Subheading,
  TextInput,
  MultiValueInput,
  Divider,
  Tile,
  VoucherTypeSelectionWrapper
} from "shared/components";
import { LEGACY_CURRENCIES } from "config/constants";
import { LegacyVoucherTypes } from "api/models";
import { Field, Form, FormSpy } from "react-final-form";
import { AccountCircle, Language } from "@mui/icons-material";
import VoucherCode from "assets/images/voucher_code.png";
import VoucherList from "assets/images/voucher_list.png";
import { StepWrapper, InputsWrappers, VoucherFieldWrapper, DividerWrapper } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useActivationPopupsVoucherSettingsStep } from "../../../hooks";
import { VoucherListControl } from "../../VoucherListControl";
import {
  voucherValueValidator,
  voucherValueTypeValidator,
  minimumOrderValueValidator,
  excludeWithOrderingValueHigherValidator,
  voucherCodeValidator
} from "./ActivationPopupsVoucherSettings.validation";
import { StepsContent } from "../../StepsContent";

export const ActivationPopupsVoucherSettings = () => {
  const { t } = useTranslation();

  const { data, metadata, hasPrev, handlePrevStep, executeSubmitActivationPopupsVoucherSettings } =
    useActivationPopupsVoucherSettingsStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitActivationPopupsVoucherSettings}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.customer.title")}</Heading>
            <Subheading>
              {t("questionnaireModule.steps.customer.activationPopupsVoucherSettings.title")}
            </Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.customer.activationPopupsVoucherSettings.description")}
            </DescriptionText>
            <InputsWrappers>
              <Field
                name="voucherValue"
                validate={voucherValueValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherValue.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherValue.placeholder`
                    )}
                    value={value ? value : ""}
                    onChange={(value) => {
                      const numericOnly = value.replace(/[^0-9.]/g, "");
                      const roundedDecimals = numericOnly.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";

                      onChange(roundedDecimals);
                    }}
                    onBlur={() => {
                      onChange(Number(value));
                      onBlur();
                    }}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["voucherValue"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["voucherValue"] ?? error)}
                    marginBottom={36}
                  />
                )}
              />
              <Field
                name="voucherValueType"
                validate={voucherValueTypeValidator}
                type="select"
                render={({
                  input: { value, onBlur, onChange, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <SelectDropdown
                    required
                    options={LEGACY_CURRENCIES}
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherValueType.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherValueType.placeholder`
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["voucherValueType"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["voucherValueType"] ?? error)}
                    marginBottom={36}
                  />
                )}
              />
            </InputsWrappers>
            <Alert>
              {t(
                "questionnaireModule.steps.customer.activationPopupsVoucherSettings.conditionsHint"
              )}
            </Alert>
            {metadata.minimumOrderValueEnabled && (
              <Field
                name="minimumOrderValue"
                validate={minimumOrderValueValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.minimumOrderValue.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.minimumOrderValue.placeholder`
                    )}
                    infoText={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.minimumOrderValue.hint`
                    )}
                    value={value ? value : ""}
                    onChange={(value) => {
                      const numericOnly = value.replace(/[^0-9.]/g, "");
                      const roundedDecimals = numericOnly.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";

                      onChange(roundedDecimals);
                    }}
                    onBlur={() => {
                      onChange(Number(value));
                      onBlur();
                    }}
                    onFocus={onFocus}
                    error={
                      (error ||
                        (submitErrors?.["minimumOrderValue"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["minimumOrderValue"] ?? error)}
                    marginTop={24}
                  />
                )}
              />
            )}
            {metadata.excludedCustomerGroupsEnabled && (
              <Field
                name="excludedCustomerGroups"
                render={({
                  input: { value, onBlur, onChange, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <MultiValueInput
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludedCustomerGroups.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludedCustomerGroups.placeholder`
                    )}
                    infoText={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludedCustomerGroups.hint`
                    )}
                    chipIcon={<AccountCircle />}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    error={
                      (error ||
                        (submitErrors?.["excludedCustomerGroups"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["excludedCustomerGroups"] ?? error)}
                  />
                )}
              />
            )}
            {metadata.excludedPagesToShowEnabled && (
              <Field
                name="excludedPagesToShowVoucher"
                render={({
                  input: { value, onBlur, onChange, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <MultiValueInput
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludedPagesToShowVoucher.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludedPagesToShowVoucher.placeholder`
                    )}
                    infoText={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludedPagesToShowVoucher.hint`
                    )}
                    chipIcon={<Language />}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    error={
                      (error ||
                        (submitErrors?.["excludedPagesToShowVoucher"] &&
                          !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["excludedPagesToShowVoucher"] ?? error)}
                    marginTop={24}
                  />
                )}
              />
            )}
            {metadata.excludeOrderingValueEnabled && (
              <Field
                name="excludeWithOrderingValueHigherThan"
                validate={excludeWithOrderingValueHigherValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludeWithOrderingValueHigherThan.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludeWithOrderingValueHigherThan.placeholder`
                    )}
                    infoText={
                      <Trans
                        t={t}
                        i18nKey={`questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.excludeWithOrderingValueHigherThan.hint`}
                      />
                    }
                    value={value ? value : ""}
                    onChange={(value) => {
                      const numericOnly = value.replace(/[^0-9.]/g, "");
                      const roundedDecimals = numericOnly.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";

                      onChange(roundedDecimals);
                    }}
                    onBlur={() => {
                      onChange(Number(value));
                      onBlur();
                    }}
                    onFocus={onFocus}
                    error={
                      (error ||
                        (submitErrors?.["excludeWithOrderingValueHigherThan"] &&
                          !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["excludeWithOrderingValueHigherThan"] ?? error)}
                  />
                )}
              />
            )}
            <DividerWrapper>
              <Divider />
            </DividerWrapper>
            <Field
              name="voucherKind"
              render={({ input: { onChange, value } }) => (
                <VoucherTypeSelectionWrapper>
                  <Tile
                    value={LegacyVoucherTypes.CODE}
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherKind.code.label`
                    )}
                    infoText={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherKind.code.hint`
                    )}
                    icon={VoucherCode}
                    onClick={onChange}
                    active={value === LegacyVoucherTypes.CODE}
                    width={260}
                    iconParams={{
                      width: 126,
                      height: 79
                    }}
                  />
                  <Tile
                    value={LegacyVoucherTypes.LIST}
                    label={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherKind.list.label`
                    )}
                    infoText={t(
                      `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucherKind.list.hint`
                    )}
                    icon={VoucherList}
                    onClick={onChange}
                    active={value === LegacyVoucherTypes.LIST}
                    width={260}
                    iconParams={{
                      width: 126,
                      height: 79
                    }}
                  />
                </VoucherTypeSelectionWrapper>
              )}
            />
            <FormSpy
              subscription={{ values: true }}
              render={({ values }) => {
                if (values.voucherKind === LegacyVoucherTypes.CODE) {
                  return (
                    <Field
                      name="voucher"
                      validate={voucherCodeValidator}
                      render={({
                        input: { value, onBlur, onChange, onFocus },
                        meta: { error, modifiedSinceLastSubmit, touched }
                      }) => (
                        <VoucherFieldWrapper>
                          <TextInput
                            required
                            label={t(
                              `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucher.label`
                            )}
                            placeholder={t(
                              `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucher.placeholder`
                            )}
                            infoText={t(
                              `questionnaireModule.steps.customer.activationPopupsVoucherSettings.form.voucher.hint`
                            )}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            onFocus={onFocus}
                            marginBottom={24}
                            error={
                              (error || (submitErrors?.["voucher"] && !modifiedSinceLastSubmit)) &&
                              touched
                            }
                            errorMessage={t(submitErrors?.["voucher"] ?? error)}
                          />
                        </VoucherFieldWrapper>
                      )}
                    />
                  );
                }
              }}
            />
            <FormSpy
              subscription={{ values: true }}
              render={({ values }) => {
                if (values.voucherKind === LegacyVoucherTypes.LIST) {
                  return <VoucherListControl submitErrors={submitErrors} />;
                }
              }}
            />
            <Alert>
              {t("questionnaireModule.steps.customer.activationPopupsVoucherSettings.hint")}
            </Alert>
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
